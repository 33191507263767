<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button @click="addServer"  v-access="{url:baseUrl,method:'POST'}" size="medium" slot="add" icon="el-icon-plus" type="primary">添加广告</el-button>
            </search>
        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <el-table-column slot="operation" fixed="right" width="150"  label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="editAd(scope.$index)" v-access="{url:baseUrl,method:'PUT'}" icon="el-icon-edit"></el-button>
<!--                        <el-button-->
<!--                            size="mini"-->
<!--                            type="danger"-->
<!--                            @click="deleteServer(scope.$index,scope.row.id)" v-access="{url:baseUrl,method:'DELETE'}" icon="el-icon-delete"></el-button>-->
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
export default {
    name: "appPcAd",
    components: {
        search,MyTable
    },
    created() {

    },
    mounted() {
        // this.$axios.put('http://fast.zhangwei.fun/park/overlay/overlay',{overlay_id:'444',path:[2,2],lng:0,lat:0,config_data:{"style":{"polyline":{"material":{"bmglFn":"PolylineGlowMaterialProperty","params":{"glowPower":0.2,"color":{"red":0.6509803921568628,"green":0.12941176470588237,"blue":0.047058823529411764,"alpha":1}}},"width":20},"useModel":true,"model":{"uri":"http:\/\/fast.zhangwei.fun\/uploads\/park\/20221208\/a63dc9474510301f80f936f5199e46f2.glb","scale":0.04,"color":{"red":1,"green":1,"blue":1,"alpha":0.56},"angel":350},"billboard":{"image":"http:\/\/fast.zhangwei.fun\/static\/tmp-img\/传感器.png"},"polygon":{"material":{"red":0.3411764705882353,"green":0.8117647058823529,"blue":0.07058823529411765,"alpha":0.27}}}},lnng:104,laasst:40});
    },
    data: function () {
        return {
            baseUrl:'/manage/app/adPc',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            subscribe:[

            ],
            userGroup: [],
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('appAdPc', 'search');
        },
        showFields: function () {
            return F.show('appAdPc', 'show');
        },
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$axios.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            // this.con(data);
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editAdPc':
                    this.$axios.put(this.baseUrl,data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.index,1,adData.data);
                    });
                    break;
                case 'addAdPc':
                    this.$axios.post(this.baseUrl,data.data).then(userData => {
                        this.page.total++;
                        this.$message.success('创建成功!');
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }
            this.form.show = false;
        },
        editAd: function (index) {
            this.$router.push('/app/pcadadd/'+this.tableData[index]['id']);
        },
        addServer:function(){
           this.$router.push('/app/pcadadd/add');
        },
        deleteServer: function (index,id) {
            this.$confirm('你真的要删除这个订阅吗?','操作确认').then(()=>{
                this.$axios.delete(this.baseUrl,{data:{id}}).then(()=>{
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style scoped>

</style>
